.projectMenu {
    padding: 0 !important;
    margin: 0 !important;
}

.projectMenu .dropdown-menu {
    font-size: 15px !important;
}

.projectMenu .nav-logo {
    margin-right: auto;
    margin-left: 36px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.projectMenu .projectMenu-panel {
    position: absolute;
    right: 3px;
}

.projectMenu .navbar-projectMenu {
    margin-top: 22px;
    margin-right: 42px;
    justify-content: flex-end;
    align-items: baseline;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.projectMenu .navbar-projectMenu .projectMenu-logo {
    height: 20px;
    margin-left: 112px;
}

.projectMenu .navbar-projectMenu .projectMenu-icon {
    border: 0;
}

.projectMenu .navbar-projectMenu .user-img {
    width: 16px;
    height: 16px;
}

.projectMenu .navbar-projectMenu .nav-link {
    padding: 0;
    padding-right: 24px;
}

.projectMenu .Langdropdown {
    font-size: 16px;
    align-self: center !important;
    padding-top: 3px;
}

.projectMenu .projectMenu-body {
    background-color: #f2f3f5;
}

.projectMenu .projectMenu-body .projectMenu-body-inner {
    padding: 0;
    margin: 0;
    height: 340px;
    background-color: white;
    border-radius: 12px 12px 12px 12px;
}

.projectMenu .projectMenu-body .projectMenu-fessicon {
    width: 100%;
    max-width: 876px;
    object-fit: cover;
    height: 340px;
    border-radius: 12px 0px 0px 12px;
}

.projectMenu .projectMenu-body .projectMenu-firsttext {
    padding: 0;
    margin: 0;
}

.projectMenu .projectMenu-body .projectMenu-firsttext .inner-text {
    font: Normal 1rem/1.5rem Meta Pro !important;
    padding-left: 77px;
    padding-right: 77px;
}

.projectMenu .projectMenu-body .projectMenu-firsttext .inner-text .inner-text-festo {
    color: #333333;
    font-size: 32px;
    line-height: 32px;
}

.projectMenu .projectMenu-body .projectMenu-firsttext .inner-text .inner-text-bodytext {
    padding-top: 23px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    text-align: left;
}

.projectMenu .projectMenu-body .projectMenu-secondtext>div>button {
    text-transform: none;
    font: Normal 0.875rem/1.5rem Meta Pro !important;
    color: #0091dc;
}

.projectMenu .projectMenu-body .projectMenu-secondtext>div>button>svg {
    margin-right: 0.5em;
}

.projectMenu .projectMenu-body .projectMenu-button>span {
    text-align: left;
}

.projectMenu .projectMenu-body .projectMenu-button {
    text-align: start;
}

.projectMenu .projectMenu-body .projectMenu-tab {
    padding-top: 8px;
    padding-bottom: 8px;
}

.projectMenu .projectMenu-body .projectMenu-tab .MuiTabScrollButton-root.Mui-disabled {
    opacity: 1;
}

.projectMenu .projectMenu-body .projectMenu-tab .tab-button {
    background: #d8dce1 0% 0% no-repeat padding-box;
    margin-right: 2px;
    width: 230px;
    height: 46px;
    border: 0;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    text-transform: unset;
}

.projectMenu .projectMenu-body .projectMenu-tab .tab-button:focus {
    outline: none;
}

.projectMenu .projectMenu-body .projectMenu-tab .tab-button span:first-of-type {
    line-height: 0.9rem !important;
}

.projectMenu .projectMenu-body .projectMenu-tab a {
    font: Normal 1rem/1.5rem Meta Pro !important;
}

.projectMenu .MuiGrid-root {
    width: 100%;
}

.projectMenu .MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 0 !important;
}

.projectMenu .MuiGrid-spacing-xs-3 {
    margin: 0 !important;
}

.projectMenu .MuiPaper-elevation4 {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.projectMenu .MuiTabs-root {
    background-color: #f2f3f5;
    max-width: 100vw;
}

.projectMenu .MuiSvgIcon-fontSizeSmall {
    font-size: 33px !important;
}

.projectMenu .MuiTabs-indicator {
    height: 0 !important;
}

.projectMenu .MuiAppBar-positionStatic {
    transform: none !important;
}

.active-projectMenu-tab {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    color: #0091dc !important;
}

.active-projectMenu-tab a {
    letter-spacing: 0px !important;
    color: #0091dc !important;
}

@media (max-width: 960px) {
    .projectMenu-body-inner {
        height: 100% !important;
    }

    .projectMenu-body-inner .projectMenu-firsttext {
        text-align: center !important;
    }

    .projectMenu-body-inner .projectMenu-firsttext .inner-text {
        padding: 0 !important;
        margin: 0 !important;
        padding-top: 15px !important;
    }

    .projectMenu-body-inner .projectMenu-firsttext .inner-text .inner-text-bodytext {
        text-align: center !important;
    }

    .projectMenu-body-inner .projectMenu-secondtext {
        text-align: center !important;
        float: none !important;
        padding: 0 !important;
        padding-top: 20px !important;
    }
}

@media (max-width: 1300px) {
    .projectMenu-secondtext {
        padding-right: 0 !important;
    }
}

.user {
    align-self: center !important;
}

@media (max-width: 599.95px) {
    .MuiTabs-scrollButtonsDesktop {
        display: inline-flex !important;
    }
}

.ruler {
    padding-top: 50px;
    padding-left: 0 !important;
    margin-left: auto !important;
}

.ruler .col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ruler .ruler-h1 {
    margin: 0 !important;
}

h4,
h5,
h6,
label,
textarea {
    font: Normal 1rem/1.5rem Meta Pro !important;
}

.project-background {
    padding-top: 20px;
    background: white;
    padding-bottom: 20px;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
}